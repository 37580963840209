import React, { FC } from "react"
import { Helmet } from "react-helmet"

type Props = {
  seo?: {
    title?: string
    link?: string
    description?: string
    keywords?: string
  }
  cardImage?: {
    image?: string
  }
  meta?: {
    name
    content
  }[]
}

const SEO: FC<Props> = ({ seo, meta, cardImage }) => {
  const ldJson = {
    "@context": ["https://schema.org"],
    "@type": "Organization",
    url: ["https://www.climatecheck.com"],
    logo: "https://climatecheck.com/logo-new-r-green.svg",
    name: "ClimateCheck",
    description:
      "ClimateCheck provides detailed climate risk data and reports for individual properties, portfolios and geographic corridors so you can assess your exposure to extreme weather events now and into the future.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "400 Village Square Blvd",
      addressLocality: "Tallahassee",
      addressCountry: "USA",
      addressRegion: "FL",
      postalCode: "32312",
    },
    contactPoint: {
      "@type": "ContactPoint",
      email: "info@climatecheck.com",
    },
  }
  return (
    <Helmet meta={meta}>
      <html lang="en" />
      <link
        rel="preload"
        as="font"
        href={"https://fonts.gstatic.com/s/muli/v22/7Auwp_0qiz-afTLGLQ.woff2"}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <title> {seo?.title || ""} / ClimateCheck</title>
      {seo?.link && <link rel="canonical" href={seo.link} />}
      <meta
        name="description"
        content={
          seo?.description ||
          "We empower property buyers, owners, and brokers by exposing and quantifying the risks related to the climate crisis through our proprietary risk assessment and report."
        }
      />
      {seo?.keywords && <meta name="keywords" content={seo.keywords} />}

      {/* TWITTER meta tags for sharing */}
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:site" content="climatecheck.com" />
      {seo?.title && <meta name="twitter:title" content={seo?.title} />}
      <meta
        property="twitter:description"
        content={
          seo?.description ||
          "We empower property buyers, owners, and brokers by exposing and quantifying the risks related to the climate crisis through our proprietary risk assessment and report."
        }
      />
      <meta
        property="twitter:image"
        content={
          cardImage
            ? cardImage
            : "https://drive.google.com/file/d/1-3ikhSgkDZcUxYyRJJlW7yLP7OpBsfZh/view"
        }
      />

      {/* META meta tags for sharing */}
      {seo?.title && <meta property="og:title" content={seo?.title} />}
      <meta
        name="description"
        property="og:description"
        content={
          seo?.description ||
          "We empower property buyers, owners, and brokers by exposing and quantifying the risks related to the climate crisis through our proprietary risk assessment and report."
        }
      />
      <meta
        name="image"
        property="og:image"
        itemProp="image"
        content={
          cardImage
            ? cardImage
            : "https://drive.google.com/file/d/1-3ikhSgkDZcUxYyRJJlW7yLP7OpBsfZh/view"
        }
      />
      <meta property="og:image:width" content="299" />
      <meta property="og:image:height" content="200" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      {seo?.link && <meta property="og:url" content={seo.link} />}
      <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
    </Helmet>
  )
}

export default SEO
